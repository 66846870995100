<script>
export default {
  name: 'NotificationComponent',
  methods: {
    /**
     * Convert a notification level to a bootstrap display variant
     */
    levelToVariant(level) {
      if (level === 'INFO') return 'info';
      if (level === 'WARN') return 'warning';
      if (level === 'ALERT') return 'danger';
      return 'info';
    },
    /**
     * Convert a notification level to a toaster title
     */
    levelToTitle(level) {
      if (level === 'INFO') return 'Info';
      if (level === 'WARN') return 'Warning';
      if (level === 'ALERT') return 'Alert';
      return 'Info';
    },
    /**
     * Dismiss a notification
     */
    dismissNotification(index) {
      this.$store.dispatch('user/dismissNotification', index);
    },
  },
  computed: {
    noNotifications() {
      return !this.$store.getters['user/notifications']
        || this.$store.getters['user/notifications'].length === 0;
    },
  },
};
</script>
<template>
  <div>
    <b-toast v-for="(notification, i) in $store.getters['user/notifications']"
            v-bind:key='i'
            v-bind:title='levelToTitle(notification.level)'
            v-bind:variant='levelToVariant(notification.level)'
            toast-class='toast-notification'
            no-close-button
            no-auto-hide
            solid
            static
            visible
            is-status>
      <b-row no-gutters align-v='center'>
        <b-col>
          {{ notification.message }}
        </b-col>
        <b-col cols='auto' align-h='end'>
          <b-btn size='sm'
                 :variant='levelToVariant(notification.level)'
                 @click='dismissNotification(i)'>
            Dismiss
          </b-btn>
        </b-col>
      </b-row>
    </b-toast>
    <p v-if="noNotifications" class='lead text-muted text-center'>
       No Notifications to Show
    </p>
  </div>
</template>

<style scoped>
  .toast, .b-toast, .toast > *, .b-toast > * {
    margin-left: auto;
    margin-right: auto;
  }
</style>
